import React, { useState, useEffect, useRef } from 'react';
import './index.css';

function Description() {
  const [isShowGzhPic, changeShowPic] = useState(false);
  const [isShowGzhPic2, changeShowPic2] = useState(false);
  const [isShowGzhPic3, changeShowPic3] = useState(false);
  const [isShowGzhPic4, changeShowPic4] = useState(false);

  return (
    <div>
      <div className="parent">
        <div className="description_header">
          <img className='h1' src="/description/h1.png" alt="" />
          <img className='h2' src="/description/h2.png" alt="" />
        </div>
        <div className="description_left">
          <a href="https://l.taptap.cn/xC8zXnnL?channel=rep-rep_bqw7aeyqjdw" target="_blank">
            <img className='android' src="/description/tap.png" alt="" />
          </a>
          <a href="https://apps.apple.com/cn/app/%E9%97%AA%E4%BA%AE%E5%A4%A7%E4%BD%9C%E6%88%98/id6670310938" target="_blank">
            <img className='ios' src="/description/ios.png" alt="" />
          </a>
        </div>
        <div className="description_right">
          {/* <p>无需下载,扫码既玩</p> */}
          <img className='wx' src="/description/1.png" alt="" />
          <img className='dj' src="/description/2.png" alt="" />
        </div>
        <div className="bg-image-container">
          <img className="bg-image" src="/description/bg.png" alt="" />
        </div>
      </div>
      
      <div className="description_footer">
        <div className="description_footer_title">
          <img className='description_footer_title_img' src="/description/title.png" alt="" />
        </div>
        <div className="description_footer_img1">
        
          <a href="#" onMouseOver={() => changeShowPic(true)} onMouseOut={() => changeShowPic(false)}>
            <img
              className="description_img"
              src={isShowGzhPic ? '/description/footer_img1_2.png' : '/description/footer_img1_1.png'}
              alt=""
            />
          </a>
          <img className={isShowGzhPic ? 'enterShow' : 'enter'} src="/description/footer_img1_enter.png" alt="" />
        </div>
          <div className="description_footer_img2">
        
          <a href="#" onMouseOver={() => changeShowPic2(true)} onMouseOut={() => changeShowPic2(false)}>
            <img
              className="description_img"
              src={isShowGzhPic2 ? '/description/footer_img2_2.png' : '/description/footer_img2_1.png'}
              alt=""
            />
          </a>
          <img className={isShowGzhPic2 ? 'enterShow' : 'enter'} src="/description/footer_img2_enter.png" alt="" />
        </div>
       
         <div className="description_footer_img3"  onMouseOver={() => changeShowPic3(true)} onMouseOut={() => changeShowPic3(false)}>
        
          <a href="https://www.xiaohongshu.com/user/profile/6641822c000000001b039c7a?xhsshare=CopyLink&appuid=6641822c000000001b039c7a&apptime=1723430339&share_id=1400df0be1164b5dbe16e25ffd5ee26b">
            <img
              className="description_img"
              src={isShowGzhPic3? '/description/footer_img3_2.png' : '/description/footer_img3_1.png'}
              alt=""
            />
          </a>
          <img className={isShowGzhPic3 ? 'enterShow' : 'enter'} src="/description/footer_img3_enter.png" alt="" />
        </div>
       
          <div className="description_footer_img4" onMouseOver={() => changeShowPic4(true)} onMouseOut={() => changeShowPic4(false)}>
        
          <a href="https://v.douyin.com/irB2MTJH/" >
            <img
              className="description_img"
              src={isShowGzhPic4? '/description/footer_img4_2.png' : '/description/footer_img4_1.png'}
              alt=""
            />
          </a>
          <img className={isShowGzhPic4 ? 'enterShow' : 'enter'} src="/description/footer_img4_enter.png" alt="" />
        </div>
       
      </div>

    </div>

  );
}

export default Description;
